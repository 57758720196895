import React, { useState } from "react";
import Divider from "../components/divider";
import { LinkCard } from "../components/mdxUI";
import { ArrowSmallRightIcon } from "@heroicons/react/20/solid";
import { Link } from "gatsby";

const Page = () => (
  <div className="max-w-[800px] px-10 pt-[60px] pb-20 mx-auto"></div>
);

export default Page;
